import 'lazysizes/plugins/bgset/ls.bgset';
import lazySizes from "lazysizes";
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import $ from "./lib/jquery";
lazySizes.init();

$( document ).ready(function() {
    /**
     * Name           : Close Signature-Canvas automatically
     * Beschreibung   : Close Signature-Canvas automatically after 3 seconds
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery, https://stackoverflow.com/questions/67770228/bootstrap-5-offcanvas-how-to-close-it-on-mouse-leave
     */
    if ($('#offcanvasSignature').length) {
        let returningUser = sessionStorage.getItem("seenOffCanvasSignature");
        let myOffcanvas = document.getElementById('offcanvasSignature');
        let overlayOffcanvas = bootstrap.Offcanvas.getOrCreateInstance(myOffcanvas);

        if (returningUser) {
            console.log("returningUser - hide signature");
            overlayOffcanvas.hide();
            $('#offcanvasSignature').remove();
        } else {
            console.log("no returningUser - show signature");
            // overlayOffcanvas.show(); /*class show via html*/
            //Delay 1sec until signature start
            setTimeout(
                function () {
                    $('#offcanvasSignature .offcanvas-body').removeClass('d-none').addClass('d-flex');
                }, 500);
            setTimeout(
                function () {
                    overlayOffcanvas.hide();
                    $('#offcanvasSignature .offcanvas-body').remove();
                    sessionStorage.setItem("seenOffCanvasSignature", true);
                }, 3500);
        }
    }
});